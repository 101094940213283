.MasterReport {
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow: hidden; 
}

.MasterReport::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0.3), /* Blanco con opacidad del 40% */
        rgba(255, 255, 255, 0.3)  /* Blanco con opacidad del 40% */
      ), url('https://firebasestorage.googleapis.com/v0/b/vivamail-d987d.appspot.com/o/background-create-client-mail.jpg?alt=media&token=0baec829-91c9-41b0-9b98-f4ee2729a0e3');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    
    z-index: -1; 
}

.MasterReport .content {
    position: relative;
    z-index: 1; 
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}
