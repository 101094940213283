.plan-management::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0.3), /* Blanco con opacidad del 40% */
        rgba(255, 255, 255, 0.3)  /* Blanco con opacidad del 40% */
      ),  url('https://firebasestorage.googleapis.com/v0/b/vivamail-d987d.appspot.com/o/background-plan-management.jpg?alt=media&token=fd180c3b-9b11-465f-87b6-eef822ee6544');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1; 
    
}

.plan-management .content {
    position: relative;
    z-index: 1; 
    padding: 20px;
    width: 100%;
    box-sizing: border-box; /* Include padding and border in width and height calculation */
}

.plan-management {
    position: relative;
    width: 100%;
    min-height: 100vh; /* Use min-height to allow content to extend the container */
    overflow: hidden; 
}

/* Carousel indicators */
.carousel-indicators {
    transform: translateY(60%);
    z-index: 100;
}

/* General carousel settings */
.carousel {
    width: 100%;
}

/* Style cards with hover effect */
.card-hover-effect {
    min-height: 400px;
    max-height: 600px;
    transition: transform 0.4s ease, box-shadow 0.4s ease;
    z-index: 1; /* Ensures that the card is above the rest */
    margin-top: 20px; /* Adjust the top margin if necessary */
}

/* Effect when hovering over cards */
.card-hover-effect:hover {
    transform: scale(1.1); /* Enlarge the card on hover */
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4); /* Add shadow */
    z-index: 2; /* Raise the card so that it is displayed on top */
    max-height: 660px; /* Ensures the card has space as it grows */
}

/* Ensures that the content of the cards is not cut off */
.carousel-item {
    overflow: visible; /* Allows cards to expand on hover */
}
.card {
    height: 390px; 
    display: flex;
    flex-direction: column;
    
}
.card-body {
    flex-grow: 1;
}
