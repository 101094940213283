.master-configuration::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0.3), /* Blanco con opacidad del 40% */
        rgba(255, 255, 255, 0.3)  /* Blanco con opacidad del 40% */
      ),  url('https://firebasestorage.googleapis.com/v0/b/vivamail-d987d.appspot.com/o/background-master-configuration.jpg?alt=media&token=a3f342fe-4963-40c5-9026-1271b16444fd');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1; 
}

.master-configuration .content {
    position: relative;
    z-index: 1; 
    padding: 20px;
    width: 100%;
    box-sizing: border-box; /* Include padding and border in width and height calculation */
}

.master-configuration {
    position: relative;
    width: 100%;
    min-height: 100vh; /* Use min-height to allow content to extend the container */
    overflow: hidden; 
}

