/* Estilos para el modal */
.modal {
    position: fixed; /* Fija el modal sobre todo el contenido */
    top: 60%; /* Centra verticalmente */
    left: 50%; /* Centra horizontalmente */
    transform: translate(-50%, -60%); /* Ajusta el desplazamiento para centrar */
    z-index: 1050; /* Asegura que esté encima de todo */
    display: block; /* Forza el display cuando está abierto */
}

.modal-backdrop {
    position: fixed; /* Asegura que el backdrop cubra toda la pantalla */
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1040; /* Debajo del modal */
}

.modal-dialog {
    max-width: 800px; /* Ajusta este valor al tamaño deseado */
    margin: 1.75rem auto; /* Para asegurarte de que esté centrado verticalmente */
}

.modal-content {
    background-color: #ece2f0; /* Color de fondo suave similar al de la imagen */
    border-radius: 10px; /* Bordes redondeados */
    padding: 20px; /* Espacio interno */
    border: none; /* Sin bordes adicionales */
    min-width: 500px;
}

.modal-header {
    border-bottom: none; /* Sin borde en el encabezado */
}

.modal-title {
    font-size: 1.5rem; /* Tamaño de la fuente para el título */
    font-weight: bold; /* Hacer que el título sea más grueso */
}

.modal-body {
    padding-top: 10px; /* Espacio entre el cuerpo y el título */
}

 /*React Quill*/
.ReactQuill {
    min-height: 200px; /* Asegura una altura mínima */
    max-height: 300px; /* Limita el alto máximo */
    overflow-y: auto; /* Permite el desplazamiento si el contenido excede el tamaño */
}

.ql-container {
    min-height: 170px; /* Ajusta la altura del contenedor del editor */
    height: 90%; /* Expande el contenedor para que ocupe todo el espacio disponible */
    background-color: white; /* Asegúrate de que el fondo sea blanco */
}

.ql-toolbar{
    border: 1px solid #ccc;
}

