.home-admin{
    background-color: #DFD8E2;
    min-height: 100vh;
}

/*Estilos para el toolbar del calendario*/
.rbc-btn-group button{
    border: none;
    color: #FFFFFF;
    font-weight: bold;
}

.rbc-btn-group button.rbc-active{
    background-color: #9776c2;
}

.rbc-btn-group button:hover, .rbc-btn-group button.rbc-active:hover{
    background-color: #DFD8E2;
    color: #7839CD;
}

.rbc-toolbar-label{
    font-weight: bold;
    text-transform: uppercase;
    font-size: 2.5vh;
}

/*Imagen de fondo para el calendario*/
.container-calendar {
    position: relative;
    width: 100%;
    height: 80vh;
    margin: 0 auto;
    border-radius: 8px; 
    overflow: hidden; 
    border: 1px solid rgba(0, 0, 0, 0.1); 
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.container-calendar::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/vivamail-d987d.appspot.com/o/calendar-image.jpg?alt=media&token=a841baa0-ee17-4388-a993-09d25a162812');
    background-size: cover;
    background-position: center;
    opacity: 0.1;
    z-index: 1;
}

.rbc-calendar {
    position: relative;
    z-index: 2; /* Asegura que el contenido del calendario esté por encima de la imagen de fondo */
}

/*Cambiar la posición de los numeros del calendario a la izquierda*/
.rbc-date-cell {
    text-align: left;
    padding-left: 1vh; 
}

/*Para darle formato a los eventos*/
.rbc-event-label {
    display: none; /* Oculta el label del evento */
}

/*Estilos para las lineas*/
.rbc-month-view {
    border-top: none;
    padding: 2vh;
}

.rbc-header + .rbc-header {
    border-left: none; 
}

.rbc-header{
    border-bottom: none;
}

.rbc-month-row:not(:first-child) .rbc-day-bg {
    border-color: black; /* Cambia el color de las líneas a negro */
}
.rbc-month-row + .rbc-month-row {
    border-top: 1px solid black; /* Cambia solo las líneas horizontales */
}

/*Estilos para los días de la semana*/
.rbc-month-header{
    margin-bottom: 1vh;
}

/*Estilos para los números*/
.rbc-date-cell .rbc-button-link{
    font-weight: bold;
    font-size: 14px;
}

.rbc-now button{
    background-color: rgb(120, 57, 205,0.6);
    color: #FFFFFF;
    border-radius: 15px; 
    padding: 1px 4px;
    margin-bottom: 1%;
}

/*Estilos para el today*/
.rbc-today {
    background-color: transparent; 
}

/*Estilos para días anteriores del mes*/
.rbc-off-range-bg {
    background-color: rgba(240, 240, 240, 0.4);
}

/*Estilos para los eventos*/
.rbc-row-segment, .rbc-overlay {
    font-size: 13px;
    line-height: 1;
}