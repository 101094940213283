.Background-Table {
    position: relative;
    width: 100%;
    min-height: 100vh;
    overflow: hidden; 
    padding-bottom: 64px;
}

.Background-Table::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('https://firebasestorage.googleapis.com/v0/b/vivamail-d987d.appspot.com/o/background-tables.jpg?alt=media&token=d579939b-b5aa-4cf7-ae80-c13f91ae0829');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: brightness(30%); 
    z-index: -1; 
}

.Background-Table .content {
    position: relative;
    z-index: 1; 
    padding: 20px;
    width: 100%;
}
