.sidebar {
    display: inline-block;
    border-radius: 200px;
    margin: 20px;
}

.btn-sidebar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
}

.search-box {
    display: flex;
    align-items: center;
    background-color: #423F3F;
    border-radius: 5px;
    padding: 5px 10px;
    width: 337px;
    margin-left: 13px;
}

.search-box input {
    background-color: transparent;
    border: none;
    color: #fff;
    outline: none;
    width: 100%;
}

.sidebar-menu {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.sidebar-menu span {
    margin-left: 18px;
}

.menu-item {
    margin-left: 60px;
    cursor: pointer;
    width: 270px;
}

.icons-size {
    font-size: 29px;
}

.sidebar-menu .arrow-icon {
    width: 23px;
    margin-left: auto;
}

.arrow-icon {
    transition: transform 0.5s ease;
}

.arrow-icon.rotate {
    transform: rotate(180deg);
}

.offcanvas-body {
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: #2c2f31 transparent;
    /* Color de la barra y el fondo */
}

.offcanvas-body::-webkit-scrollbar {
    width: 9px;
}

.offcanvas-body::-webkit-scrollbar-track {
    background: transparent;
}

.offcanvas-body::-webkit-scrollbar-thumb {
    background-color: #2c2f31;
    border-radius: 10px;
}

.logout-container {
    width: 120px;
    margin-left: 30px;
    padding: 6px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.change-password-btn{
    background-color: #7839CD;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 8px 10px;
    cursor: pointer;
    
}

.change-password-btn:hover {
    background-color: #ac94cc;
}